import { useCallback, useEffect, useState } from "react";
import { ProductType } from "../utilities";
import { useProduct } from "../hooks/useProduct";
import { useLocation } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import ProductCard from "../components/ProductCard";
import { RxCrossCircled } from "react-icons/rx";

export const Products = () => {
  var Query = useLocation().state || {};

  const {
    isLoading,
    isError,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  } = useProduct({
    category: Query.searchQuery && !Query.isSearch ? Query.searchQuery : "",
    home_product: "",
    recommended: "",
  });

  const [products, setProducts] = useState<ProductType[]>([]);

  const filter = () => {
    var allData: ProductType[] = [];

    data?.pages.map((group: any) => {
      group.data.results.map((product: ProductType) => {
        allData.push(product);
      });
    });

    if (Query.isSearch) {
      allData = allData.filter(
        (product: ProductType) =>
          product.category
            ?.toLowerCase()
            .includes(Query.searchQuery?.toLowerCase()) ||
          product.name?.toLowerCase().includes(Query.searchQuery?.toLowerCase())
      );
    }

    setProducts(allData);
  };

  useEffect(() => {
    filter();
  }, [data]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 1200 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (products.length < 21 && hasNextPage) fetchNextPage();
  if (products.length === 0 && !isFetching && Query.isSearch)
    return (
      <div className="bg-[#755138] min-h-screen flex justify-center items-center">
        <h1>Not Found!</h1>
      </div>
    );
  if (isLoading) {
    return (
      <div className="bg-[#755138] min-h-screen flex justify-center items-center">
        <ImSpinner2 size={36} className="spinner" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-screen flex justify-center bg-[#755138] items-center">
        Something Went Wrong
      </div>
    );
  }

  return (
    <div className="py-32 px-4 min-h-screen bg-[#755138] flex flex-col gap-y-4">
      {/* {Query.searchQuery ? (
        <div className="flex justify-center items-center gap-x-4">
          <div className="bg-[#402c1f] rounded-xl flex w-56 self-center items-center justify-center gap-x-4 p-2">
            <div className="text-[#e5d3b3]">
              {Query.searchQuery.length > 15
                ? Query.searchQuery.substring(0, 15) + "..."
                : Query.searchQuery.substring(0, 15)}
            </div>
          </div>
          <RxCrossCircled
            size={28}
            className="hover:cursor-pointer hover:text-[#e5d3b3] transition duration-200"
            onClick={() => {
              Query = { searchQuery: "", isSearch: false };
            }}
          />
        </div>
      ) : null} */}
      <div className="medium-product-boxes gap-20 mb-16 sm:px-10">
        {products?.map((product: ProductType, index: number) => {
          return <ProductCard key={index} product={product} />;
        })}
      </div>
      <div className="flex justify-center items-center">
        {isFetchingNextPage || isFetching ? (
          <div className="bg-[#755138] flex justify-center items-center">
            <ImSpinner2 size={36} className="spinner" />
          </div>
        ) : null}
      </div>
    </div>
  );
};
